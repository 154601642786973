<template>
  <div class="c102">
    <div>
      <p class="c49 c65"><span class="c3 c59"></span></p>
    </div>
    <p class="c19">
      <span class="c129"
        >CONDITIONS D&rsquo;UTILISATION DES SERVICES DE dok.ma</span
      ><span class="c3 c125">&nbsp;</span>
    </p>
    <p class="c28 c49"><span class="c6 c3"></span></p>
    <p class="c28"><span class="c6 c3">1. OBJET</span></p>
    <p class="c28">
      <span class="c1"
        >Les pr&eacute;sentes CGU ont pour objet de d&eacute;finir les
        conditions dans lesquelles les Utilisateurs peuvent acc&eacute;der aux
        Services et les utiliser.
      </span>
    </p>
    <p class="c145">
      <span class="c1"
        >Tout Utilisateur qui acc&egrave;de aux Services propos&eacute;s par </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >s&#39;engage &agrave; respecter, sans r&eacute;serve, les
        pr&eacute;sentes CGU, compl&eacute;t&eacute;es, le cas
        &eacute;ch&eacute;ant, des CPU. Ces CPU sont </span
      ><span class="c5">notifi&eacute;s</span
      ><span class="c1"
        >&nbsp;avant l&rsquo;utilisation des Services aux Utilisateurs pour
        acceptation expresse et pr&eacute;alable.</span
      >
    </p>
    <p class="c134">
      <span class="c1"
        >Si l&rsquo;Utilisateur n&rsquo;est pas en accord avec tout ou partie
        des CGU, il ne doit pas utiliser le Site et les Services </span
      ><span class="c0 c3">dok.ma</span><span class="c1">. </span>
    </p>
    <p class="c137">
      <span class="c0 c3">dok.ma </span
      ><span class="c1"
        >est libre de modifier, &agrave; tout moment, les pr&eacute;sentes CGU,
        afin notamment de prendre en compte toute &eacute;volution
        l&eacute;gale, r&eacute;glementaire, jurisprudentielle et/ou technique.
        La version qui pr&eacute;vaut est celle qui est accessible en ligne
        &agrave; l&rsquo;adresse suivante : </span
      ><span class="c0 c3">https://www.dok.ma/terms</span
      ><span class="c1"
        >. Il en est de m&ecirc;me pour les &eacute;ventuelles CPU accessibles
        en ligne &agrave; l&rsquo;adresse des Services. Tout Utilisateur est
        donc tenu de se r&eacute;f&eacute;rer &agrave; leur version accessible
        en ligne &agrave; la date de son acc&egrave;s et de son utilisation des
        Services.
      </span>
    </p>
    <p class="c25">
      <span class="c1"
        >L&rsquo;Utilisateur est express&eacute;ment inform&eacute; que
        l&rsquo;unique version des CGU des Services qui fait foi est celle qui
        se trouve en ligne sur le Site, ce qu&rsquo;il reconna&icirc;t et
        accepte sans restriction, s&rsquo;engageant &agrave; s&rsquo;y
        r&eacute;f&eacute;rer syst&eacute;matiquement lors de chaque
        connexion.</span
      >
    </p>
    <p class="c139"><span class="c6">2. D&Eacute;FINITIONS</span></p>
    <p class="c91">
      <span class="c1"
        >Au sens des conditions g&eacute;n&eacute;rales d&rsquo;utilisation, les
        expressions ci-dessous auront la d&eacute;finition suivante :
      </span>
    </p>
    <p class="c80">
      <span class="c20">&#9679; </span><span class="c5">&laquo; </span
      ><span class="c6">CGU </span
      ><span class="c5"
        >&raquo; d&eacute;signe les pr&eacute;sentes conditions
        g&eacute;n&eacute;rales d&rsquo;utilisation applicables aux Services.
        <br /></span
      ><span class="c20">&#9679; </span><span class="c5">&laquo; </span
      ><span class="c6"
        >Donn&eacute;es &agrave; caract&egrave;re personnel </span
      ><span class="c1"
        >&raquo; d&eacute;signe toute information concernant une personne
        physique identifi&eacute;e ou identifiable ; est r&eacute;put&eacute;e
        identifiable une personne qui peut &ecirc;tre identifi&eacute;e,
        directement ou indirectement, notamment par r&eacute;f&eacute;rence
        &agrave; un num&eacute;ro d&rsquo;identification ou &agrave; un ou
        plusieurs &eacute;l&eacute;ments sp&eacute;cifiques, propres &agrave;
        son identit&eacute; physique, physiologique, psychique,
        &eacute;conomique, culturelle ou sociale.
      </span>
    </p>
    <p class="c67">
      <span class="c20">&#9679; </span><span class="c5">&laquo; </span
      ><span class="c6"
        >Donn&eacute;es &agrave; caract&egrave;re personnel de Sant&eacute; </span
      ><span class="c1"
        >&raquo; d&eacute;signe toute Donn&eacute;e &agrave; caract&egrave;re
        personnel de l&rsquo;Utilisateur recueillie par un Professionnel de
        sant&eacute; &agrave; l&rsquo;occasion d&rsquo;activit&eacute;s de
        pr&eacute;vention, de diagnostic, de soins ou de suivi social et
        m&eacute;dico-social et notamment dans le cadre de l&rsquo;utilisation
        des Services.
      </span>
    </p>
    <p class="c104">
      <span class="c20">&#9679; </span><span class="c5">&laquo; </span
      ><span class="c6">Identifiant </span
      ><span class="c5"
        >&raquo; d&eacute;signe l&rsquo;identifiant personnel associ&eacute; au
        compte de l&rsquo;Utilisateur ainsi que tout code confidentiel ou mot de
        passe d&eacute;livr&eacute; &agrave; l&rsquo;Utilisateur par </span
      ><span class="c0">dok.ma </span
      ><span class="c1"
        >puis modifi&eacute; par l&rsquo;Utilisateur et permettant &agrave;
        l&rsquo;Utilisateur de s&rsquo;identifier afin d&rsquo;acc&eacute;der
        aux Services.
      </span>
    </p>
    <p class="c101">
      <span class="c20">&#9679; </span><span class="c5">&laquo; </span
      ><span class="c6">Loi n&deg; 09-08 </span
      ><span class="c1"
        >&raquo; d&eacute;signe le Dahir n&deg; 1-09-15 du 22 safar 1430 (18
        f&eacute;vrier 2009) portant promulgation de la loi n&deg; 09-08
        relative &agrave; la protection des personnes physiques &agrave;
        l&rsquo;&eacute;gard du traitement des donn&eacute;es &agrave;
        caract&egrave;re personnel
      </span>
    </p>
    <p class="c14">
      <span class="c20">&#9679; </span><span class="c5">&laquo; </span
      ><span class="c6">Parties </span
      ><span class="c5">&raquo; d&eacute;signe conjointement </span
      ><span class="c0">dok.ma </span
      ><span class="c1">et l&rsquo;Utilisateur. </span>
    </p>
    <p class="c48">
      <span class="c20">&#9679; </span><span class="c5">&laquo; </span
      ><span class="c6">Professionnel de sant&eacute; </span
      ><span class="c1"
        >&raquo; d&eacute;signe les professionnels intervenant dans le secteur
        de la sant&eacute;, et qui sont pr&eacute;sent&eacute;s sur le Site.
      </span>
    </p>
    <p class="c13">
      <span class="c20">&#9679; </span><span class="c5">&laquo; </span
      ><span class="c6">Services </span
      ><span class="c5"
        >&raquo; d&eacute;signe conjointement le Service d&rsquo;Annuaire et le
        Service de Prise de Rendez-Vous en Ligne mis &agrave; disposition des
        Utilisateurs par </span
      ><span class="c0">dok.ma </span
      ><span class="c5">par le biais du Site. </span
      ><span class="c20">&#9679; </span><span class="c5">&laquo; </span
      ><span class="c6">Service d&rsquo;Annuaire </span
      ><span class="c5"
        >&raquo; d&eacute;signe le service gratuit de recherche de
        Professionnels de sant&eacute; mis &agrave; disposition des Utilisateurs
        par </span
      ><span class="c0">dok.ma </span
      ><span class="c1">par le biais du Site. </span>
    </p>
    <p class="c73">
      <span class="c20">&#9679; </span><span class="c5">&laquo; </span
      ><span class="c6">Service de Prise de Rendez-Vous en Ligne </span
      ><span class="c1"
        >&raquo; d&eacute;signe le Service de prise de rendez-vous en ligne
        aupr&egrave;s de Professionnels de sant&eacute; mis &agrave; disposition
        des Utilisateurs par le biais du Site.
      </span>
    </p>
    <p class="c14">
      <span class="c20">&#9679; </span><span class="c5">&laquo; </span
      ><span class="c6">Site </span
      ><span class="c5">&raquo; d&eacute;signe le site Internet </span
      ><span class="c0">www.dok.ma</span><span class="c1">. </span>
    </p>
    <p class="c143">
      <span class="c20">&#9679; </span><span class="c5">&laquo; </span
      ><span class="c6">Utilisateur </span
      ><span class="c5"
        >&raquo; d&eacute;signe toute personne physique qui utilise le Site et
        acc&egrave;de aux Services propos&eacute;s par </span
      ><span class="c0">dok.ma </span
      ><span class="c1">par le biais du Site. </span>
    </p>
    <p class="c27">
      <span class="c1">Sauf indications contraires dans les CGU : </span>
    </p>
    <p class="c92">
      <span class="c20">&#9679; </span
      ><span class="c1"
        >D&egrave;s lors qu&rsquo;un mot ou une phrase a un sens d&eacute;fini,
        toute autre forme de ce mot ou cette phrase a un sens correspondant ;
      </span>
    </p>
    <p class="c30">
      <span class="c20">&#9679; </span
      ><span class="c1"
        >Les mots au singulier incluent le pluriel et vice versa ;
      </span>
    </p>
    <p class="c78">
      <span class="c20">&#9679; </span
      ><span class="c1"
        >Une r&eacute;f&eacute;rence &agrave; un document, une norme, une
        disposition l&eacute;gislative, code ou tout autre document implique
        toute modification ou mise &agrave; jour de ce document, norme,
        disposition l&eacute;gislative ou code ;</span
      >
    </p>
    <p class="c144">
      <span class="c20">&#9679; </span
      ><span class="c1"
        >Si une p&eacute;riode de temps est sp&eacute;cifi&eacute;e et remonte
        &agrave; un jour donn&eacute; ou au jour d&rsquo;acceptation des CGU
        cette p&eacute;riode de temps doit &ecirc;tre calcul&eacute;e comme
        comprenant ce jour-l&agrave;.
      </span>
    </p>
    <p class="c25">
      <span class="c6">3.</span><span class="c3 c118">&nbsp;</span
      ><span class="c6">CAPACIT&Eacute;</span><span class="c15 c3">&nbsp;</span>
    </p>
    <p class="c25">
      <span class="c1"
        >L&rsquo;Utilisation des Services est r&eacute;serv&eacute;e aux
        Utilisateurs personnes physiques capables de souscrire des obligations
        conform&eacute;ment au droit marocain ou par lequel il a
        &eacute;t&eacute; mandat&eacute; &agrave; cet effet.
      </span>
    </p>
    <p class="c108">
      <span class="c1"
        >L&rsquo;Utilisateur peut utiliser les Services pour le compte de tiers
        sur lesquels il dispose de l&rsquo;autorit&eacute; parentale ou pour
        lesquels il est reconnu tuteur ou curateur conform&eacute;ment au droit
        marocain.
      </span>
    </p>
    <p class="c47">
      <span class="c6"
        >4. INFORMATIONS, RESPONSABILIT&Eacute;S ET GARANTIES RELATIVES AUX
        MOYENS D&#39;ACC&Egrave;S AUX SERVICES
      </span>
    </p>
    <p class="c16">
      <span class="c0 c3">dok.ma </span
      ><span class="c1"
        >met en place les moyens n&eacute;cessaires &agrave; la bonne marche des
        Services. </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >prend les mesures n&eacute;cessaires au maintien de la
        continuit&eacute; et de la qualit&eacute; des Services.
      </span>
    </p>
    <p class="c88">
      <span class="c1"
        >L&rsquo;Utilisateur reconna&icirc;t que son utilisation des Services se
        fait &agrave; ses risques et p&eacute;rils. Les Services lui sont
        fournis &laquo; en l&rsquo;&eacute;tat &raquo; et sont accessibles sans
        aucune garantie de disponibilit&eacute; et de r&eacute;gularit&eacute; </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >s&rsquo;efforcera cependant de rendre les Services accessibles 24
        heures sur 24, 7 jours sur 7, sauf en cas de force majeure ou d&rsquo;un
        &eacute;v&eacute;nement hors du contr&ocirc;le de </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >et sous r&eacute;serve des p&eacute;riodes de maintenance, des pannes
        &eacute;ventuelles, des al&eacute;as techniques li&eacute;s &agrave; la
        nature du r&eacute;seau internet ou des actes de malveillance ou toute
        atteinte port&eacute;e au mat&eacute;riel ou aux logiciels de </span
      ><span class="c0 c3">dok.ma</span><span class="c1">. </span>
    </p>
    <p class="c39">
      <span class="c0 c3">dok.ma </span
      ><span class="c1"
        >ne peut &ecirc;tre tenue responsable des perturbations du r&eacute;seau
        Internet dues &agrave; des cas de force majeure au sens de la
        jurisprudence de la Cour de Cassation marocaine et du fait des
        op&eacute;rations de maintenance des Services planifi&eacute;es par </span
      ><span class="c0 c3">dok.ma</span><span class="c1">. </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >ne peut &eacute;galement pas &ecirc;tre tenue responsable de
        l&rsquo;installation et du fonctionnement des terminaux utilis&eacute;s
        par l&rsquo;Utilisateur pour acc&eacute;der aux Services et non fournis
        par </span
      ><span class="c0 c3">dok.ma</span><span class="c1">. </span>
    </p>
    <p class="c37">
      <span class="c1">Plus g&eacute;n&eacute;ralement, </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >ne pourra en aucun cas &ecirc;tre tenue pour responsable en raison
        d&rsquo;une interruption des Services </span
      ><span class="c5">quelle</span
      ><span class="c1"
        >&nbsp;que soit la cause, la dur&eacute;e ou la fr&eacute;quence de
        cette interruption.
      </span>
    </p>
    <p class="c98">
      <span class="c1"
        >Les taux de transfert et les temps de r&eacute;ponse des informations
        circulant &agrave; partir de la Plateforme de </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1">vers Internet ne sont pas garantis par </span
      ><span class="c0 c3">dok.ma</span><span class="c1">. </span>
    </p>
    <p class="c74">
      <span class="c1"
        >L&rsquo;Utilisateur reconna&icirc;t que la vitesse de transmission des
        informations ne d&eacute;pend pas des Services offerts par </span
      ><span class="c0 c3">dok.ma</span
      ><span class="c1"
        >, mais des caract&eacute;ristiques inh&eacute;rentes aux r&eacute;seaux
        de communications &eacute;lectronique et des caract&eacute;ristiques
        techniques de son mode de connexion (c&acirc;ble, ADSL, 3G, 4G etc.) et
        de son acc&egrave;s Internet.</span
      >
    </p>
    <p class="c11">
      <span class="c0 c3">dok.ma </span
      ><span class="c1"
        >ne saurait en aucun cas &ecirc;tre tenue de r&eacute;parer
        d&rsquo;&eacute;ventuels dommages indirects subis par
        l&rsquo;Utilisateur &agrave; l&rsquo;occasion de l&rsquo;utilisation des
        Services. Les dommages indirects sont ceux qui ne r&eacute;sultent pas
        exclusivement et directement de la d&eacute;faillance des Services de </span
      ><span class="c0 c3">dok.ma</span><span class="c1">. </span>
    </p>
    <p class="c96">
      <span class="c1">En outre, la responsabilit&eacute; de </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >ne peut pas &ecirc;tre recherch&eacute;e pour des actes
        r&eacute;alis&eacute;s par l&rsquo;Utilisateur ou un tiers utilisant les
        Services.
      </span>
    </p>
    <p class="c79">
      <span class="c1"
        >Tout logiciel, t&eacute;l&eacute;charg&eacute; par l&rsquo;Utilisateur,
        ou obtenu de toute autre mani&egrave;re lors de l&rsquo;utilisation du
        Service, l&rsquo;est aux risques et p&eacute;rils de
        l&rsquo;Utilisateur.
      </span>
    </p>
    <p class="c110">
      <span class="c6"
        >5. INFORMATIONS PR&Eacute;ALABLES RELATIVES AUX SERVICES</span
      ><span class="c15 c3">&nbsp;</span>
    </p>
    <p class="c110">
      <span class="c1"
        >Le Site offre la possibilit&eacute; &agrave; l&rsquo;Utilisateur
        d&rsquo;utiliser :
      </span>
    </p>
    <p class="c100">
      <span class="c12 c3">&#9679; </span
      ><span class="c1">le Service d&rsquo;Annuaire ; </span>
    </p>
    <p class="c61">
      <span class="c12 c3">&#9679; </span
      ><span class="c1">le Service de Prise de Rendez-Vous en Ligne ; et </span>
    </p>
    <p class="c61">
      <span class="c12 c3">&#9679; </span
      ><span class="c1"
        >de recevoir des informations et documents de la part de ses praticiens.
      </span>
    </p>
    <p class="c140">
      <span class="c1"
        >Les Services sont fournis &agrave; l&rsquo;Utilisateur &agrave; titre
        gratuit.
      </span>
    </p>
    <p class="c90">
      <span class="c1"
        >N&eacute;anmoins, les &eacute;quipements (notamment ordinateur,
        t&eacute;l&eacute;phone, logiciels, moyens de communication
        &eacute;lectronique) permettant l&rsquo;acc&egrave;s et
        l&rsquo;utilisation des Services sont &agrave; la charge exclusive de
        l&rsquo;Utilisateur, de m&ecirc;me que les frais de communications
        &eacute;lectroniques (notamment co&ucirc;ts t&eacute;l&eacute;phoniques,
        co&ucirc;ts d&rsquo;acc&egrave;s &agrave; Internet) r&eacute;sultant de
        leur utilisation. Il appartient &agrave; l&rsquo;Utilisateur de
        s&rsquo;informer du prix d&rsquo;utilisation desdits &eacute;quipements
        ou services aupr&egrave;s des op&eacute;rateurs concern&eacute;s.
        L&rsquo;Utilisateur est seul redevable de leurs prix.
      </span>
    </p>
    <p class="c81">
      <span class="c1"
        >Par ailleurs, l&rsquo;Utilisateur reconna&icirc;t que le Site renvoie
        &agrave; des prestations de services payantes. Notamment et par exemple,
        suite &agrave; une r&eacute;servation effectu&eacute;e par le biais du
        Service de Prise de Rendez-Vous en Ligne du Site, l&rsquo;Utilisateur
        reconna&icirc;t et accepte que la prestation de sant&eacute;
        effectu&eacute;e par le Professionnel de sant&eacute; inscrit sur le
        Site soit payante. Lorsque </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >fournit sur son Site des d&eacute;tails concernant les tarifs des
        honoraires d&rsquo;un Professionnel de sant&eacute;, ces informations ne
        sont fournies qu&rsquo;&agrave; titre indicatif et par souci de
        commodit&eacute;. En aucun cas </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >ne garantit l&rsquo;exactitude de telles informations.
      </span>
    </p>
    <p class="c34"><span class="c6">6. SERVICE D&rsquo;ANNUAIRE </span></p>
    <p class="c87">
      <span class="c1"
        >Le Service d&rsquo;Annuaire permet &agrave; l&rsquo;Utilisateur de
        rechercher un Professionnel de sant&eacute; conform&eacute;ment &agrave;
        diff&eacute;rents crit&egrave;res (g&eacute;olocalisation,
        sp&eacute;cialit&eacute;, langues parl&eacute;es, homme/femme, etc.) et
        de consulter la page des Professionnels de sant&eacute; ainsi
        recherch&eacute;s.
      </span>
    </p>
    <p class="c111">
      <span class="c0 c58">dok.ma</span><span class="c0 c3">&nbsp;</span
      ><span class="c1"
        >ne garantit pas &agrave; l&rsquo;Utilisateur que son annuaire contienne
        l&rsquo;ensemble des professionnels de sant&eacute; exer&ccedil;ant sur
        le territoire marocain et plus sp&eacute;cifiquement sur la zone
        g&eacute;ographique de recherche de l&rsquo;Utilisateur. En
        cons&eacute;quence, il est possible que l&rsquo;Utilisateur ne trouve
        pas un professionnel de sant&eacute; sur le Site en utilisant le Service
        d&rsquo;Annuaire.
      </span>
    </p>
    <p class="c105">
      <span class="c0 c3">dok.ma </span
      ><span class="c1"
        >renvoie l&rsquo;Utilisateur au site des Ordres professionnels
        comp&eacute;tents s&rsquo;agissant des professionnels de sant&eacute;
        exer&ccedil;ant une profession r&eacute;glement&eacute;e et aux sites
        des syndicats repr&eacute;sentatifs des professionnels de sant&eacute;
        n&rsquo;exer&ccedil;ant pas une profession r&eacute;glement&eacute;e
        afin d&rsquo;obtenir la liste exhaustive de membres de chaque profession
        concern&eacute;e.</span
      >
    </p>
    <p class="c23">
      <span class="c0 c3">dok.ma </span
      ><span class="c1"
        >rappelle &agrave; l&rsquo;Utilisateur qu&rsquo;en aucun cas le Service
        d&rsquo;Annuaire ne peut &ecirc;tre assimil&eacute;, du fait de son
        absence d&rsquo;exhaustivit&eacute;, &agrave; un service
        d&rsquo;adressage de patients aux Professionnels de Sant&eacute;.
      </span>
    </p>
    <p class="c72">
      <span class="c1"
        >L&rsquo;Utilisateur conserve toujours le choix des Professionnels de
        sant&eacute; qu&rsquo;ils consultent conform&eacute;ment aux
        dispositions applicables du Code de la sant&eacute; publique, aux
        dispositions d&eacute;ontologiques applicables aux professions
        r&eacute;glement&eacute;es et aux dispositions contraignantes
        applicables aux professions non r&eacute;glement&eacute;es.
      </span>
    </p>
    <p class="c35">
      <span class="c6">7. SERVICE DE PRISE DE RENDEZ-VOUS EN LIGNE </span>
    </p>
    <p class="c128">
      <span class="c1"
        >L&rsquo;Utilisateur peut, &agrave; tout moment, prendre un rendez-vous
        en ligne avec un Professionnel de sant&eacute; pour une consultation
        &agrave; un horaire indiqu&eacute; comme disponible par le Professionnel
        de sant&eacute; sur le Portail et que l&rsquo;Utilisateur aura
        pr&eacute;alablement identifi&eacute; au sein du Portail par le biais du
        Service d&rsquo;Annuaire.
      </span>
    </p>
    <p class="c95">
      <span class="c1"
        >Chaque rendez-vous est transmis en temps r&eacute;el au Professionnel
        de sant&eacute;.
      </span>
    </p>
    <p class="c99">
      <span class="c1"
        >L&rsquo;Utilisateur peut &eacute;galement g&eacute;rer ses rendez-vous
        (annulation, modification) et suivre l&rsquo;historique de ses
        rendez-vous.
      </span>
    </p>
    <p class="c2">
      <span class="c1"
        >L&#39;Utilisateur sollicite et donc autorise le Professionnel de
        sant&eacute;, via </span
      ><span class="c0 c3">dok.ma</span><span class="c1">, et/ou </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >&agrave; lui envoyer des SMS et e-mails dont l&rsquo;objet est de lui
        d&eacute;livrer des informations au sujet des rendez-vous qu&rsquo;il a
        pris sur le Site.
      </span>
    </p>
    <p class="c36">
      <span class="c1"
        >L&rsquo;Utilisateur autorise &eacute;galement le Professionnel de
        sant&eacute;, via </span
      ><span class="c0 c3">dok.ma</span><span class="c1">, et/ou </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >&agrave; lui notifier par SMS ou e-mail toute information pratique
        concernant les Professionnels de Sant&eacute; qui font partie de la
        liste &laquo; Mon &eacute;quipe soignante &raquo; accessible sur le
        compte de chaque Utilisateur, ou toute information de sant&eacute;
        envoy&eacute;e par ces m&ecirc;mes Professionnels de sant&eacute;. Tout
        Professionnel de sant&eacute; chez qui l&rsquo;Utilisateur a pris
        rendez-vous est automatiquement ajout&eacute; &agrave; cette liste.
        L&rsquo;Utilisateur a la possibilit&eacute; de modifier sa liste
        directement depuis son compte.
      </span>
    </p>
    <p class="c141">
      <span class="c0 c3">dok.ma </span
      ><span class="c1"
        >et le Professionnel de sant&eacute; d&eacute;clinent toute
        responsabilit&eacute; en cas d&rsquo;erreur dans la saisie de ses
        coordonn&eacute;es par l&rsquo;Utilisateur rendant impossible
        l&rsquo;envoi de tout SMS ou de tout e-mail n&eacute;cessaire au bon
        fonctionnement et &agrave; la bonne administration du Service de Prise
        de </span
      ><span class="c5">Rendez-vous</span
      ><span class="c1">&nbsp;en Ligne. </span>
    </p>
    <p class="c113">
      <span class="c1"
        >Il appartient &agrave; l&rsquo;Utilisateur de faire toutes
        v&eacute;rifications qui semblent n&eacute;cessaires ou opportunes avant
        de proc&eacute;der &agrave; une quelconque r&eacute;servation chez
        l&rsquo;un des Professionnels de sant&eacute; inscrits sur le </span
      ><span class="c0 c3">Site. dok.ma </span
      ><span class="c1"
        >n&rsquo;est aucunement responsable de l&rsquo;exactitude des
        informations qui figurent sur la page de chaque Professionnel de
        sant&eacute;. L&rsquo;exactitude et la mise &agrave; jour de ces
        donn&eacute;es sont sous l&rsquo;enti&egrave;re responsabilit&eacute; du
        Professionnel de Sant&eacute;.
      </span>
    </p>
    <p class="c33">
      <span class="c1"
        >L&rsquo;utilisation des Services ne dilue, ne modifie ou
        n&rsquo;att&eacute;nue ni directement, ni indirectement la
        responsabilit&eacute; et les obligations des Professionnels de
        sant&eacute; inscrits aux Services vis-&agrave;-vis des Utilisateurs.
        Les Professionnels de sant&eacute; exercent leur pratique
        professionnelle en toute ind&eacute;pendance, selon leurs obligations
        d&eacute;ontologiques, l&eacute;gales et r&eacute;glementaires
        personnelles et sous leur responsabilit&eacute; exclusive.
      </span>
    </p>
    <p class="c26">
      <span class="c0 c3">dok.ma </span
      ><span class="c1"
        >n&rsquo;est en aucun cas responsable d&rsquo;une quelconque annulation
        ou indisponibilit&eacute; du Professionnel de sant&eacute;
        cons&eacute;cutivement &agrave; l&rsquo;identification et &agrave; la
        programmation d&rsquo;un rendez-vous par l&rsquo;Utilisateur via le
        Service de Prise de Rendez-Vous en Ligne.</span
      >
    </p>
    <p class="c117">
      <span class="c1"
        >L&rsquo;Utilisateur s&rsquo;engage &agrave; compl&eacute;ter toutes les
        Informations n&eacute;cessaires qui lui sont demand&eacute;es en vue de
        la bonne utilisation du Service de Prise de Rendez-Vous en Ligne.
      </span>
    </p>
    <p class="c57">
      <span class="c1"
        >L&rsquo;Utilisateur est inform&eacute; du fait que la programmation
        d&rsquo;un rendez-vous via le Service de Prise de Rendez-Vous en Ligne
        constitue un engagement ferme de sa part, et que toute </span
      ><span class="c5">non-pr&eacute;sentation</span
      ><span class="c1"
        >&nbsp;&agrave; un rendez-vous programm&eacute; doit faire l&rsquo;objet
        d&rsquo;une information pr&eacute;alable d&rsquo;annulation ou de report
        aupr&egrave;s du Professionnel de sant&eacute; concern&eacute;. Cette
        information peut s&rsquo;effectuer soit via le syst&egrave;me
        d&rsquo;annulation propos&eacute; via le Site, soit par tous moyens de
        contact du Professionnel de sant&eacute; inscrit, au choix de
        l&rsquo;Utilisateur. L&rsquo;utilisateur reconna&icirc;t &ecirc;tre
        inform&eacute; qu&rsquo;il assume exclusivement les cons&eacute;quences
        de sa non-pr&eacute;sentation &agrave; un rendez-vous vis-&agrave;-vis
        du Professionnel de Sant&eacute; concern&eacute;. L&rsquo;Utilisateur
        est &eacute;galement inform&eacute; que s&rsquo;il est
        d&eacute;clar&eacute; absent par un m&ecirc;me Professionnel de
        sant&eacute; &agrave; trois de ses rendez-vous, il ne lui sera plus
        possible de prendre rendez-vous en ligne jusqu&rsquo;&agrave; tant que
        le Professionnel de sant&eacute; concern&eacute; ne d&eacute;bloque sa
        possibilit&eacute; de prise de </span
      ><span class="c5">rendez-vous</span><span class="c1">. </span>
    </p>
    <p class="c103">
      <span class="c6"
        >8. INSCRIPTION ET ACC&Egrave;S AU SERVICE DE RENDEZ-VOUS EN LIGNE
      </span>
    </p>
    <p class="c22">
      <span class="c1"
        >Apr&egrave;s demande de cr&eacute;ation de son compte de
        l&rsquo;Utilisateur, </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >d&eacute;livre &agrave; l&rsquo;Utilisateur des Identifiants afin que
        l&rsquo;Utilisateur puisse acc&eacute;der &agrave; son compte
        Utilisateur. Lors de sa demande d&rsquo;ouverture de son compte
        Utilisateur, l&rsquo;Utilisateur fournit des informations vraies,
        exactes, &agrave; jour et compl&egrave;tes sur son identit&eacute; comme
        demand&eacute;es dans le formulaire internet. Il s&rsquo;engage
        notamment &agrave; ne pas cr&eacute;er une fausse identit&eacute; de
        nature &agrave; induire </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >ou les tiers en erreur et &agrave; ne pas usurper
        l&rsquo;identit&eacute; d&rsquo;une autre personne physique.
        L&rsquo;Utilisateur s&rsquo;engage &agrave; mettre imm&eacute;diatement
        &agrave; jour, en cas de modification, les donn&eacute;es qu&rsquo;il a
        communiqu&eacute;es initialement.
      </span>
    </p>
    <p class="c97">
      <span class="c1"
        >Dans l&rsquo;hypoth&egrave;se o&ugrave; l&rsquo;Utilisateur fournirait
        des informations fausses, inexactes, p&eacute;rim&eacute;es,
        incompl&egrave;tes, trompeuses ou de nature &agrave; induire en erreur, </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >pourra, imm&eacute;diatement sans pr&eacute;avis ni indemnit&eacute;,
        suspendre ou r&eacute;silier le compte Utilisateur de
        l&rsquo;Utilisateur et lui refuser l&rsquo;acc&egrave;s, de fa&ccedil;on
        temporaire ou d&eacute;finitive &agrave; tout ou partie des Services.
      </span>
    </p>
    <p class="c46">
      <span class="c1"
        >Le compte Utilisateur comprend notamment ses Identifiants et
        plac&eacute;s sous la responsabilit&eacute; exclusive de
        l&rsquo;Utilisateur. L&rsquo;Utilisateur s&rsquo;oblige &agrave; les
        conserver secrets et &agrave; ne pas les divulguer sous quelque forme
        que ce soit. Si l&rsquo;un des &eacute;l&eacute;ments
        d&rsquo;identification de l&rsquo;Utilisateur est perdu ou vol&eacute;,
        ce dernier doit informer sans d&eacute;lai </span
      ><span class="c0 c3">dok.ma</span
      ><span class="c1"
        >, qui proc&eacute;dera alors &agrave; l&rsquo;annulation et/ou la mise
        &agrave; jour imm&eacute;diate(s) de l&rsquo;Identifiant
        concern&eacute;.
      </span>
    </p>
    <p class="c63">
      <span class="c1"
        >L&rsquo;Utilisateur est seul responsable de l&rsquo;utilisation qui est
        faite de ses Identifiants, m&ecirc;me si l&rsquo;Utilisateur
        pr&eacute;enregistre sur son ordinateur, son t&eacute;l&eacute;phone
        mobile ou sur n&rsquo;importe quel autre type d&rsquo;&eacute;quipement
        son Identifiant, permettant ainsi la connexion automatique aux Services.
      </span>
    </p>
    <p class="c133">
      <span class="c1"
        >Tout acc&egrave;s, utilisation des Services et transmission de
        donn&eacute;es effectu&eacute;s &agrave; partir du compte Utilisateur
        d&rsquo;un Utilisateur sera r&eacute;put&eacute; avoir &eacute;t&eacute;
        effectu&eacute; par ce dernier. La sauvegarde de la
        confidentialit&eacute; des Identifiants de l&rsquo;Utilisateur
        rel&egrave;ve de l&rsquo;enti&egrave;re responsabilit&eacute; de ce
        dernier. </span
      ><span class="c5">&Agrave;</span
      ><span class="c1"
        >&nbsp;cet &eacute;gard, l&rsquo;Utilisateur est tenu de s&rsquo;assurer
        qu&rsquo;&agrave; l&rsquo;issue de chaque session</span
      ><span class="c5">, il</span
      ><span class="c1"
        >&nbsp;se d&eacute;connecte effectivement des Services, en particulier
        lorsqu&rsquo;il acc&egrave;de aux Services &agrave; partir d&rsquo;un
        ordinateur public.
      </span>
    </p>
    <p class="c17">
      <span class="c1"
        >Toute perte, d&eacute;tournement ou utilisation non autoris&eacute;e
        des Identifiants d&rsquo;un Utilisateur et leurs cons&eacute;quences
        rel&egrave;vent de la seule responsabilit&eacute; de cet Utilisateur.
        Dans tous les cas mentionn&eacute;s</span
      ><span class="c5">&nbsp;ci-dessus</span
      ><span class="c1">, l&rsquo;Utilisateur est tenu d&rsquo;en avertir </span
      ><span class="c0 c3">dok.ma</span
      ><span class="c1">, sans d&eacute;lai, via </span
      ><span class="c5">le Formulaire</span
      ><span class="c1">&nbsp;d&#39;aide, afin que </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >puisse proc&eacute;der &agrave; la r&eacute;initialisation du compte
        Utilisateur.
      </span>
    </p>
    <p class="c52">
      <span class="c1"
        >Chaque Utilisateur peut &agrave; tout moment r&eacute;silier la
        souscription de son compte Utilisateur en contactant </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1">via le Formulaire d&#39;aide. </span>
    </p>
    <p class="c9">
      <span class="c6">9. SITES TIERS</span><span class="c118 c3">&nbsp;</span>
    </p>
    <p class="c136">
      <span class="c1"
        >Pour le cas o&ugrave; les Services contiendraient des liens hypertextes
        renvoyant vers des sites Internet &eacute;dit&eacute;s par des tiers
        (ci-apr&egrave;s les &laquo; </span
      ><span class="c6 c3">Sites Tiers </span
      ><span class="c1">&raquo;) sur lesquels </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1">n&#39;exerce aucune sorte de contr&ocirc;le, </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >n&#39;assume aucune responsabilit&eacute; quant au contenu des Sites
        Tiers ou au contenu vers lequel les Sites Tiers peuvent renvoyer. La
        pr&eacute;sence de liens hypertextes vers des Sites Tiers ne saurait
        signifier que </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >approuve de quelque fa&ccedil;on que ce soit les contenus des Sites </span
      ><span class="c0 c3">Tiers. dok.ma </span
      ><span class="c1"
        >n&#39;est responsable d&#39;aucune modification ou mise &agrave; jour
        concernant les Sites Tiers. </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >n&#39;est pas responsable de la transmission d&#39;informations
        &agrave; partir des Sites Tiers, ni du mauvais fonctionnement de
        ceux-ci.
      </span>
    </p>
    <p class="c35">
      <span class="c6">10. PROPRI&Eacute;T&Eacute; INTELLECTUELLE</span
      ><span class="c15 c3">&nbsp;</span>
    </p>
    <p class="c106">
      <span class="c1">Les Services de </span><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >et tous les &eacute;l&eacute;ments qui les composent sont, sauf
        mentions particuli&egrave;res, la propri&eacute;t&eacute; exclusive de </span
      ><span class="c0 c3">dok.ma</span
      ><span class="c1"
        >. Toutes les marques et tous les logos appartenant &agrave; </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >ne peuvent pas &ecirc;tre utilis&eacute;s par l&rsquo;Utilisateur sans
        le consentement pr&eacute;alable &eacute;crit de </span
      ><span class="c0 c3">dok.ma</span><span class="c1">. </span>
    </p>
    <p class="c135">
      <span class="c1"
        >En cons&eacute;quence, en application des dispositions
        l&eacute;gislatives et r&eacute;glementaires marocaines applicable en
        mati&egrave;re de propri&eacute;t&eacute; intellectuelle, des
        dispositions l&eacute;gislatives et r&eacute;glementaires de tous pays
        et des conventions internationales, toute reproduction, diffusion ou
        repr&eacute;sentation, int&eacute;grale ou partielle, des Services de </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >ou d&rsquo;un quelconque &eacute;l&eacute;ment qui les compose est
        interdite de m&ecirc;me que leur alt&eacute;ration. A ce titre, il est
        notamment interdit &agrave; l&rsquo;Utilisateur d&rsquo;adapter,
        arranger, modifier, corriger, associer, </span
      ><span class="c5">traduite</span
      ><span class="c1"
        >&nbsp;en toutes langues ou tous langages, mettre sur le march&eacute;
        &agrave; titre gratuit ou on&eacute;reux, commercialiser, tout ou partie
        des Services fournis par </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >ou d&rsquo;un quelconque &eacute;l&eacute;ment qui les compose, quel
        qu&rsquo;en soient le moyen et le support. Aucune stipulation des CGU ne
        peut &ecirc;tre interpr&eacute;t&eacute;e comme une cession de droits de
        propri&eacute;t&eacute; intellectuelle que ce soit tacitement ou
        d&rsquo;une autre fa&ccedil;on.
      </span>
    </p>
    <p class="c41">
      <span class="c6"
        >11. PROTECTION DES DONN&Eacute;ES &Agrave; CARACT&Egrave;RE PERSONNEL
      </span>
    </p>
    <p class="c62">
      <span class="c5">Par</span
      ><span class="c8"
        >&nbsp;le biais de ce formulaire, dok.ma collecte vos donn&eacute;es
        personnelles en vue de :</span
      >
    </p>
    <p class="c84">
      <span class="c8 c3"
        >&#9679; Permettre leur navigation sur le Site et permettre leur
        utilisation de l&rsquo;Application ;
      </span>
    </p>
    <p class="c84">
      <span class="c8 c3"
        >&#9679; Les mettre en relation avec un Professionnel de Sant&eacute; ;
      </span>
    </p>
    <p class="c55">
      <span class="c8 c3"
        >&#9679; Leur permettre de visualiser et g&eacute;rer leurs parcours de
        soin, tant pour les rendez-vous pris directement sur le Site, que les
        rendez-vous renseign&eacute;s par leurs Professionnels de Sant&eacute;
        directement ;
      </span>
    </p>
    <p class="c51">
      <span class="c8 c3"
        >&#9679; Leur permettre de partager des documents avec leurs
        Professionnels de Sant&eacute; ;
      </span>
    </p>
    <p class="c51">
      <span class="c8 c3"
        >&#9679; Leur permettre de g&eacute;rer les rendez-vous de leurs proches
        et leurs parcours de soin.
      </span>
    </p>
    <p class="c51">
      <span class="c8 c3"
        >&#9679; Leur permettre d&rsquo;&ecirc;tre pris en charge de
        fa&ccedil;on optimale par le Professionnel de Sant&eacute;.
      </span>
    </p>
    <p class="c49 c51"><span class="c8 c3"></span></p>
    <p class="c51">
      <span class="c8 c3"
        >&Agrave; titre subsidiaire les donn&eacute;es des Utilisateurs sont
        &eacute;galement collect&eacute;es pour :
      </span>
    </p>
    <p class="c9">
      <span class="c8 c3"
        >&#9679; Pr&eacute;venir et lutter contre la fraude informatique
        (pollupostage, piratage...) ;
      </span>
    </p>
    <p class="c18">
      <span class="c8 c3"
        >&#9679; Am&eacute;liorer la navigation sur le Site et
        l&rsquo;utilisation de l&rsquo;Application ;
      </span>
    </p>
    <p class="c75">
      <span class="c8 c3"
        >&#9679; Mener des enqu&ecirc;tes facultatives sur les services de
        dok.ma et leurs possibles &eacute;volutions (ces enqu&ecirc;tes sont
        r&eacute;alis&eacute;es de mani&egrave;re anonyme o&ugrave; sont
        anonymis&eacute;es &agrave; bref d&eacute;lai) ;
      </span>
    </p>
    <p class="c75">
      <span class="c8 c3"
        >&#9679; Mener des enqu&ecirc;tes facultatives sur la qualit&eacute; de
        leur prise en charge par leurs Professionnels de Sant&eacute; (&agrave;
        l&rsquo;initiative des Professionnels de Sant&eacute;) ;
      </span>
    </p>
    <p class="c40">
      <span class="c8"
        >&#9679; Effectuer des statistiques anonymis&eacute;es sur
        l&rsquo;utilisation de l&rsquo;outil qui seront communiqu&eacute;es au
        public, un reporting interne pour les &eacute;quipes de recherche &amp;
        d&eacute;veloppement de dok.ma ainsi qu&rsquo;un reporting &agrave;
        destination des Professionnels de Sant&eacute; (sans qu&rsquo;aucune
        Donn&eacute;e de Sant&eacute; ne soit utilis&eacute;e).
      </span>
    </p>
    <p class="c62">
      <span class="c8 c3"
        >Ce traitement a fait l&rsquo;objet d&rsquo;une demande
        d&rsquo;autorisation aupr&egrave;s de la CNDP.<br />Les donn&eacute;es
        personnelles collect&eacute;es peuvent &ecirc;tre transmises &agrave;
        Genious communication Maroc,
      </span>
    </p>
    <p class="c62">
      <span class="c8"
        >Vous pouvez vous adresser &agrave; dok.ma pour exercer vos droits
        d&rsquo;acc&egrave;s, de rectification et d&rsquo;opposition
        conform&eacute;ment aux dispositions de la loi 09-08 en envoyant un mail
        &agrave; : </span
      ><span class="c77"
        ><a class="c60" href="mailto:contact.dataprivacy@dok.ma"
          >contact.dataprivacy@dok.m</a
        ></span
      >
    </p>
    <p class="c109">
      <span class="c0 c3">dok.ma </span
      ><span class="c1"
        >invite express&eacute;ment l&rsquo;Utilisateur &agrave; consulter sa </span
      ><span class="c0 c3"
        >Politique relative &agrave; la protection des donn&eacute;es
        personnelles </span
      ><span class="c1"
        >qui fait partie int&eacute;grante des pr&eacute;sentes CGU.
      </span>
    </p>
    <p class="c70">
      <span class="c12 c3">&#9679; </span
      ><span class="c3 c20 c124">Cookies et adresse IP </span>
    </p>
    <p class="c82">
      <span class="c0 c3">dok.ma </span
      ><span class="c1"
        >invite express&eacute;ment l&rsquo;Utilisateur &agrave; consulter sa </span
      ><span class="c0 c3"
        >Politique relative &agrave; la protection des donn&eacute;es
        personnelles </span
      ><span class="c1"
        >qui fait partie int&eacute;grante des pr&eacute;sentes CGU.
      </span>
    </p>
    <p class="c76">
      <span class="c5">&Agrave;</span
      ><span class="c1"
        >&nbsp;la demande des autorit&eacute;s judiciaires, </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >peut transmettre l&rsquo;adresse IP de l&rsquo;Utilisateur, afin que ce
        dernier soit identifi&eacute; en coop&eacute;ration avec son fournisseur
        d&rsquo;acc&egrave;s &agrave; Internet.
      </span>
    </p>
    <p class="c27"><span class="c6 c3">12. FORCE MAJEURE</span></p>
    <p class="c27 c49"><span class="c6 c3"></span></p>
    <p class="c7">
      <span class="c1"
        >Il y a force majeure lorsqu&#39;un &eacute;v&eacute;nement
        &eacute;chappant au contr&ocirc;le du d&eacute;biteur, qui ne pouvait
        &ecirc;tre raisonnablement pr&eacute;vu lors de l&rsquo;acceptation des
        CGU et dont les effets ne peuvent &ecirc;tre &eacute;vit&eacute;s par
        des mesures appropri&eacute;es, emp&ecirc;che l&#39;ex&eacute;cution de
        son obligation par le d&eacute;biteur.
      </span>
    </p>
    <p class="c66">
      <span class="c1"
        >Le cas de force majeure suspend les obligations de la partie
        concern&eacute;e pendant le temps o&ugrave; jouera la force majeure si
        cet &eacute;v&eacute;nement est temporaire. N&eacute;anmoins, les
        Parties s&rsquo;efforceront d&rsquo;en minimiser dans toute la mesure du
        possible les cons&eacute;quences.
      </span>
    </p>
    <p class="c119">
      <span class="c5">&Agrave;</span
      ><span class="c1"
        >&nbsp;d&eacute;faut, si l&#39;emp&ecirc;chement est d&eacute;finitif,
        les parties seront lib&eacute;r&eacute;es de leurs obligations dans les
        conditions pr&eacute;vues dans le Code des obligations et des contrats.
      </span>
    </p>
    <p class="c50"><span class="c6">13. CONVENTION DE PREUVE </span></p>
    <p class="c93">
      <span class="c1"
        >Les messages re&ccedil;us par t&eacute;l&eacute;copie ou par voie
        &eacute;lectronique et plus g&eacute;n&eacute;ralement les documents
        &eacute;lectroniques &eacute;chang&eacute;s entre </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >et l&rsquo;Utilisateur ont la m&ecirc;me valeur que celle
        accord&eacute;e &agrave; l&rsquo;original. Il convient de conserver les
        t&eacute;l&eacute;copies ou les &eacute;crits &eacute;lectroniques de
        telle mani&egrave;re qu&rsquo;ils puissent constituer des copies
        fid&egrave;les et durables, au sens des dispositions du Code des
        obligations et des contrats.</span
      >
    </p>
    <p class="c107">
      <span class="c6">14. RENONCIATION</span><span class="c15 c3">&nbsp;</span>
    </p>
    <p class="c126">
      <span class="c1"
        >Le fait pour l&rsquo;une ou l&rsquo;autre des Parties de ne pas se
        pr&eacute;valoir d&rsquo;une ou plusieurs stipulations des CGU ne pourra
        en aucun cas impliquer la renonciation par cette Partie &agrave;
        s&rsquo;en pr&eacute;valoir ult&eacute;rieurement.
      </span>
    </p>
    <p class="c29"><span class="c6">15. NULLIT&Eacute; PARTIELLE </span></p>
    <p class="c31">
      <span class="c1"
        >Dans le cas o&ugrave; certaines stipulations des CGU seraient
        inapplicables pour quelque raison que ce soit, y compris en raison
        d&rsquo;une loi ou d&rsquo;une r&eacute;glementation applicable, les
        parties resteront li&eacute;es par les autres stipulations des CGU et
        s&rsquo;efforceront de rem&eacute;dier aux clauses inapplicables dans le
        m&ecirc;me esprit que celui qui a pr&eacute;sid&eacute; lors de la
        conclusion.
      </span>
    </p>
    <p class="c85"><span class="c6">16. MENTIONS L&Eacute;GALES </span></p>
    <p class="c71">
      <span class="c1">Les Services de </span><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >sont exploit&eacute;s et h&eacute;berg&eacute;s par </span
      ><span class="c0 c3">dok.ma</span><span class="c1">,</span
      ><span class="c6 c3">&nbsp;Key Agency</span
      ><span class="c1"
        >, soci&eacute;t&eacute; &agrave; responsabilit&eacute; limit&eacute;e
        d&rsquo;associ&eacute; unique au capital de 100 000 dirhams, dont le
        si&egrave;ge social est situ&eacute; au 1 impasse du moulin,
        r&eacute;sidence Les Terrasses de Gueliz RDC appartement 1,
        Gu&eacute;liz - Marrakech,</span
      ><span class="c5">&nbsp;sous la direction de Mr </span
      ><span class="c6">Samuel Krief </span><span class="c5">et </span
      ><span class="c1"
        >&nbsp;immatricul&eacute;e au registre de commerce de Marrakech sous le
        num&eacute;ro 94773.
      </span>
    </p>
    <p class="c56">
      <span class="c6"
        >17. DROIT APPLICABLE ET ATTRIBUTION DE COMP&Eacute;TENCE
      </span>
    </p>
    <p class="c64">
      <span class="c1"
        >L&rsquo;Utilisateur est inform&eacute; du fait que le Site
        s&rsquo;adresse aux patients r&eacute;sidant au Maroc et est soumise en
        droit marocain. Aussi, en naviguant sur le Site ou en utilisant les
        Services de </span
      ><span class="c0 c3">dok.ma</span
      ><span class="c1"
        >, l&rsquo;Utilisateur se soumet au droit marocain.
      </span>
    </p>
    <p class="c116">
      <span class="c1"
        >Toutes difficult&eacute;s relatives &agrave; la validit&eacute;,
        l&rsquo;application ou &agrave; l&rsquo;interpr&eacute;tation des CGU
        seront soumises, &agrave; d&eacute;faut d&rsquo;accord amiable, au
        Tribunal d&rsquo;Instance de Marrakech, auquel les Parties attribuent
        comp&eacute;tence territoriale, quel que soit le lieu
        d&rsquo;ex&eacute;cution ou le domicile du d&eacute;fendeur.</span
      >
    </p>
    <p class="c49 c116"><span class="c1"></span></p>
    <p class="c53">
      <span class="c6"
        >18. CONDITIONS PARTICULI&Egrave;RES D&rsquo;UTILISATION DU SERVICE DE
        CONSULTATION PAR VID&Eacute;O
      </span>
    </p>
    <p class="c131">
      <span class="c1"
        >Les pr&eacute;sentes Conditions Particuli&egrave;res
        d&rsquo;Utilisation (ci-apr&egrave;s &laquo; CPU &raquo;)
        s&rsquo;appliquent au Service de consultation par vid&eacute;o (ou
        T&eacute;l&eacute;consultation) propos&eacute; par </span
      ><span class="c0 c3">dok.ma</span
      ><span class="c1"
        >. Elles compl&egrave;tent les Conditions G&eacute;n&eacute;rales
        d&rsquo;Utilisation des Services </span
      ><span class="c0 c3">dok.ma</span
      ><span class="c1"
        >. Si tel n&rsquo;&eacute;tait pas d&eacute;j&agrave; le cas,
        l&rsquo;acceptation des pr&eacute;sentes CPU emporte acceptation pleine
        et enti&egrave;re des Conditions G&eacute;n&eacute;rales
        d&rsquo;Utilisation aux Services </span
      ><span class="c0 c3">dok.ma</span><span class="c1">. </span>
    </p>
    <p class="c83">
      <span class="c6">18.1. D&Eacute;FINITIONS ADDITIONNELLES</span
      ><span class="c15 c3">&nbsp;</span>
    </p>
    <p class="c54">
      <span class="c1"
        >Au sens des conditions particuli&egrave;res d&rsquo;utilisation, les
        expressions ci-dessous auront la d&eacute;finition suivante :
      </span>
    </p>
    <p class="c68">
      <span class="c12 c3">&#9679; </span><span class="c1">&laquo; </span
      ><span class="c6 c3">Application Mobile </span
      ><span class="c1">&raquo; d&eacute;signe l&#39;application </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >disponible depuis un smartphone par suite du
        t&eacute;l&eacute;chargement de celle-ci sur un magasin
        d&rsquo;applications. </span
      ><span class="c12 c3">&#9679; </span><span class="c1">&laquo; </span
      ><span class="c6 c3">Site Mobile </span
      ><span class="c1">&raquo; d&eacute;signe le site internet </span
      ><span class="c0 c3">www.dok.ma </span
      ><span class="c1">accessible depuis un smartphone. </span>
    </p>
    <p class="c114">
      <span class="c3 c12">&#9679; </span><span class="c1">&laquo; </span
      ><span class="c6 c3">CPU </span
      ><span class="c1"
        >&raquo; d&eacute;signe les pr&eacute;sentes conditions
        particuli&egrave;res d&rsquo;utilisation applicables au Service de
        Consultation par Vid&eacute;o &eacute;galement nomm&eacute; &laquo;
        Service de T&eacute;l&eacute;consultation &raquo;. </span
      ><span class="c12 c3">&#9679; </span><span class="c1">&laquo; </span
      ><span class="c6 c3">Service(s) de T&eacute;l&eacute;consultation </span
      ><span class="c1">&raquo; ou &laquo; </span
      ><span class="c3 c6">Service de Consultation par Vid&eacute;o </span
      ><span class="c1"
        >&raquo; d&eacute;signe la mise &agrave; disposition par </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >&agrave; l&rsquo;Utilisateur d&rsquo;un outil permettant de
        r&eacute;aliser une consultation &agrave; distance par
        vid&eacute;otransmission avec un Professionnel de sant&eacute; au
        travers de l&rsquo;Application Mobile ou du Site Mobile.
      </span>
    </p>
    <p class="c43">
      <span class="c12 c3">&#9679; </span><span class="c1">&laquo; </span
      ><span class="c6 c3">T&eacute;l&eacute;consultation </span
      ><span class="c1">&raquo; ou &laquo; </span
      ><span class="c6 c3">Consultation par Vid&eacute;o </span
      ><span class="c1"
        >&raquo; d&eacute;signe une consultation &agrave; distance entre un
        Utilisateur et un Professionnel de sant&eacute; r&eacute;alis&eacute;e
        par vid&eacute;otransmission via l&rsquo;Application Mobile ou le Site
        Mobile.
      </span>
    </p>
    <p class="c34">
      <span class="c6">18.2. OBJET DES CPU ET VERSION EN VIGUEUR</span
      ><span class="c15 c3">&nbsp;</span>
    </p>
    <p class="c115">
      <span class="c1"
        >Les pr&eacute;sentes CPU ont pour objet de d&eacute;finir les
        conditions dans lesquelles les Utilisateurs peuvent acc&eacute;der au
        Service de T&eacute;l&eacute;consultation et l&rsquo;utiliser. Tout
        Utilisateur qui acc&egrave;de au Service de
        T&eacute;l&eacute;consultation propos&eacute; par </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >s&#39;engage &agrave; respecter, sans r&eacute;serve, les
        pr&eacute;sentes CPU. Ces CPU sont </span
      ><span class="c5">notifi&eacute;s</span
      ><span class="c1"
        >&nbsp;avant l&rsquo;utilisation du Service aux Utilisateurs pour
        acceptation expresse et pr&eacute;alable. Si l&rsquo;Utilisateur
        n&rsquo;est pas en accord avec tout ou partie des CPU, il ne doit pas
        utiliser le Service de T&eacute;l&eacute;consultation. </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >est libre de modifier les CPU &agrave; tout moment, afin notamment de
        prendre en compte toute &eacute;volution l&eacute;gale,
        r&eacute;glementaire, jurisprudentielle et/ou technique. La version qui
        pr&eacute;vaut est celle qui est accessible en ligne au jour de
        l&rsquo;utilisation du Service de T&eacute;l&eacute;consultation. Tout
        Utilisateur est donc tenu de se r&eacute;f&eacute;rer &agrave; cette
        version &agrave; la date de son utilisation dudit service.
      </span>
    </p>
    <p class="c34">
      <span class="c6"
        >18.3. PROTECTION DES DONN&Eacute;ES &Agrave; CARACT&Egrave;RE PERSONNEL
      </span>
    </p>
    <p class="c86">
      <span class="c0 c3">dok.ma </span
      ><span class="c1"
        >ou tout autre tiers n&rsquo;ont pas acc&egrave;s &agrave; la
        T&eacute;l&eacute;consultation ou aux donn&eacute;es
        &eacute;chang&eacute;es entre l&#39;Utilisateur et les Professionnels de
        sant&eacute;. Elles restent strictement confidentielles. </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >invite express&eacute;ment l&rsquo;Utilisateur &agrave; consulter les
        dispositions sp&eacute;cifiques de sa </span
      ><span class="c0 c3"
        >Politique relative &agrave; la protection des donn&eacute;es
        personnelles</span
      ><span class="c3 c38">, </span
      ><span class="c1"
        >applicables &agrave; la T&eacute;l&eacute;consultation, qui fait partie
        int&eacute;grante des pr&eacute;sentes CPU.</span
      >
    </p>
    <p class="c21"><span class="c1"></span></p>
    <p class="c21"><span class="c1"></span></p>
    <p class="c14">
      <span class="c6"
        >18.4. INFORMATIONS RELATIVES AUX MOYENS D&#39;ACC&Egrave;S ET AU
        SERVICE
      </span>
    </p>
    <p class="c138">
      <span class="c1"
        >Afin d&rsquo;acc&eacute;der au Service de
        T&eacute;l&eacute;consultation et b&eacute;n&eacute;ficier d&rsquo;une
        T&eacute;l&eacute;consultation de qualit&eacute;, l&rsquo;Utilisateur
        doit disposer &agrave; minima, des &eacute;quipements </span
      ><span class="c5">suivants :</span
      ><span class="c1"
        >&nbsp;(1) un smartphone suffisamment r&eacute;cent disposant
        d&rsquo;une cam&eacute;ra comportant une r&eacute;solution satisfaisante
        et (2) une connexion internet (en 4G) avec un d&eacute;bit suffisant. Si
        tel n&rsquo;est pas le cas, le Professionnel de sant&eacute; pourra
        mettre un terme &agrave; la T&eacute;l&eacute;consultation et s&rsquo;il
        le juge opportun, pr&eacute;lever l&rsquo;Utilisateur de frais
        aff&eacute;rents aux actes d&eacute;j&agrave; effectu&eacute;s. </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >n&rsquo;est qu&rsquo;un interm&eacute;diaire entre l&rsquo;Utilisateur
        et le Professionnel de sant&eacute;, leur permettant d&rsquo;effectuer
        une consultation &agrave; distance par vid&eacute;otransmission,
        l&rsquo;envoi de documents de mani&egrave;re &eacute;lectronique et le
        paiement en ligne.
      </span>
    </p>
    <p class="c122">
      <span class="c1"
        >Le Service de T&eacute;l&eacute;consultation est fourni &agrave;
        l&rsquo;Utilisateur par </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >&agrave; titre gratuit. Les &eacute;quipements (smartphone, connexion
        internet...) permettant l&rsquo;acc&egrave;s et l&rsquo;utilisation des
        Services de T&eacute;l&eacute;consultation sont &agrave; la charge
        exclusive de l&rsquo;Utilisateur, de m&ecirc;me que les frais de
        communications &eacute;lectroniques (notamment co&ucirc;ts
        t&eacute;l&eacute;phoniques, co&ucirc;ts d&rsquo;acc&egrave;s &agrave;
        Internet) y aff&eacute;rents. Il appartient &agrave; l&rsquo;Utilisateur
        de s&rsquo;informer du prix d&rsquo;utilisation desdits
        &eacute;quipements ou services aupr&egrave;s des op&eacute;rateurs
        concern&eacute;s avant d&rsquo;utiliser le Service de
        T&eacute;l&eacute;consultation.
      </span>
    </p>
    <p class="c45">
      <span class="c1"
        >L&rsquo;Utilisateur reconna&icirc;t que le Service de
        T&eacute;l&eacute;consultation permet de b&eacute;n&eacute;ficier
        d&rsquo;une prestation de sant&eacute; payante et il en sera redevable
        au Professionnel de sant&eacute;.
      </span>
    </p>
    <p class="c120">
      <span class="c1">Les informations fournies par </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >sur les tarifs ou honoraires pratiqu&eacute;s par un Professionnel de
        Sant&eacute; le sont &agrave; titre indicatif et par souci de
        commodit&eacute;. </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >ne garantit pas l&rsquo;exactitude de telles informations. Le prix
        final de la T&eacute;l&eacute;consultation peut varier selon le type de
        consultation effectivement r&eacute;alis&eacute;. Il appartient au
        Professionnel de sant&eacute; d&#39;informer le patient du co&ucirc;t de
        cet acte.
      </span>
    </p>
    <p class="c121">
      <span class="c6"
        >18.5. CONDITIONS D&#39;ACC&Egrave;S ET D&rsquo;UTILISATION DU SERVICE
        DE T&Eacute;L&Eacute;CONSULTATION
      </span>
    </p>
    <p class="c24">
      <span class="c6 c3"
        >La T&eacute;l&eacute;consultation n&#39;est pas un service
        d&#39;urgence. En cas d&rsquo;urgence, l&rsquo;Utilisateur doit appeler
        le 15 ou le 112 ou se diriger vers un service d&#39;urgence.
      </span>
    </p>
    <p class="c127">
      <span class="c1"
        >La T&eacute;l&eacute;consultation se fait, pour le patient,
        exclusivement depuis un smartphone (via l&rsquo;Application Mobile ou le
        Site Mobile). Le smartphone doit &ecirc;tre &eacute;quip&eacute;
        d&rsquo;une cam&eacute;ra.
      </span>
    </p>
    <p class="c130">
      <span class="c1"
        >L&rsquo;Utilisateur s&rsquo;engage &agrave; compl&eacute;ter toutes les
        informations n&eacute;cessaires qui lui sont demand&eacute;es et
        indispensables &agrave; la bonne utilisation du Service de
        T&eacute;l&eacute;consultation. Lors de la prise de rendez-vous pour une
        T&eacute;l&eacute;consultation, l&rsquo;Utilisateur doit notamment
        renseigner les informations bancaires qui seront n&eacute;cessaires au
        paiement de la T&eacute;l&eacute;consultation. Le syst&egrave;me de
        paiement est externalis&eacute; aupr&egrave;s d&rsquo;un prestataire de
        services de paiement agr&eacute;&eacute;.
      </span>
    </p>
    <p class="c89">
      <span class="c1"
        >Lors du rendez-vous, l&rsquo;Utilisateur est invit&eacute; &agrave; se
        pr&eacute;senter &agrave; la T&eacute;l&eacute;consultation quelques
        minutes avant celle-ci afin de s&rsquo;assurer de la qualit&eacute; de
        la connexion de l&rsquo;audio et de la vid&eacute;o du smartphone.
      </span>
    </p>
    <p class="c105">
      <span class="c1"
        >Lors de sa connexion en vue de la T&eacute;l&eacute;consultation,
        l&rsquo;Utilisateur est mis en attente par le Professionnel de
        sant&eacute; pour une dur&eacute;e laiss&eacute;e &agrave;
        l&#39;appr&eacute;ciation de ce dernier. Le Professionnel de
        sant&eacute; active ensuite le d&eacute;marrage de la
        T&eacute;l&eacute;consultation. </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >ne saurait &ecirc;tre tenu responsable des retards ou annulation des
        Professionnels de sant&eacute;.
      </span>
    </p>
    <p class="c142">
      <span class="c1"
        >L&rsquo;Utilisateur s&rsquo;engage &agrave; utiliser le Service de
        T&eacute;l&eacute;consultation dans des conditions lui permettant
        d&rsquo;assurer la confidentialit&eacute; et le bon d&eacute;roulement
        des &eacute;changes avec le Professionnel de sant&eacute;. En</span
      >
    </p>
    <p class="c23">
      <span class="c1"
        >particulier, l&rsquo;Utilisateur doit s&rsquo;assurer de
        r&eacute;aliser la T&eacute;l&eacute;consultation dans un endroit
        suffisamment lumineux et calme pour permettre une
        T&eacute;l&eacute;consultation de qualit&eacute;.
      </span>
    </p>
    <p class="c42">
      <span class="c1"
        >Le Professionnel de sant&eacute; est seul juge de la pertinence
        d&rsquo;une prise en charge &agrave; distance de l&rsquo;Utilisateur et
        peut interrompre au besoin la T&eacute;l&eacute;consultation si les
        conditions d&rsquo;une prise en charge &agrave; distance ne sont pas
        r&eacute;unies. La dur&eacute;e d&rsquo;une
        T&eacute;l&eacute;consultation est laiss&eacute;e &agrave; la libre
        appr&eacute;ciation du Professionnel de sant&eacute;. Lors de la
        T&eacute;l&eacute;consultation, l&rsquo;Utilisateur doit se conformer
        aux instructions pr&eacute;cis&eacute;es par le Professionnel de
        sant&eacute;, pour une prise en charge optimale ou un diagnostic. Les
        Professionnels de sant&eacute; r&eacute;alisent la
        T&eacute;l&eacute;consultation en toute ind&eacute;pendance, suivant les
        obligations d&eacute;ontologiques et l&eacute;gales qui leur incombent.
        La T&eacute;l&eacute;consultation se d&eacute;roule sous la
        responsabilit&eacute; exclusive de l&rsquo;Utilisateur et du
        Professionnel de sant&eacute;.
      </span>
    </p>
    <p class="c69">
      <span class="c1"
        >L&#39;Utilisateur s&#39;engage &agrave; ne pas enregistrer, copier ou
        diffuser tout contenu ou extrait de contenu en lien avec la
        T&eacute;l&eacute;consultation quel qu&#39;en soit le moyen, le support,
        le proc&eacute;d&eacute; ou la finalit&eacute;. La
        T&eacute;l&eacute;consultation n&rsquo;est enregistr&eacute;e ni par le
        Professionnel de sant&eacute;, ni par </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >ni par tout autre tiers. L&#39;Utilisateur est inform&eacute; que toute
        atteinte au droit &agrave; l&rsquo;image, au respect de la vie
        priv&eacute;e ou au secret professionnel et m&eacute;dical peut faire
        l&rsquo;objet de sanctions, y compris p&eacute;nales.
      </span>
    </p>
    <p class="c10">
      <span class="c1"
        >En cas de dysfonctionnement de quelque nature que ce soit lors de la
        T&eacute;l&eacute;consultation, l&rsquo;Utilisateur doit en informer
        imm&eacute;diatement le Professionnel de sant&eacute;.
        L&rsquo;Utilisateur qui interrompt abusivement la
        T&eacute;l&eacute;consultation engage sa responsabilit&eacute; &agrave;
        l&rsquo;&eacute;gard du Professionnel de sant&eacute;.
      </span>
    </p>
    <p class="c32">
      <span class="c5">&Agrave;</span
      ><span class="c1"
        >&nbsp;l&rsquo;issue de la T&eacute;l&eacute;consultation, &agrave;
        laquelle le Professionnel de sant&eacute; met fin, le Professionnel de
        sant&eacute; confirme le montant &agrave; payer par l&rsquo;Utilisateur.
        Selon la situation, le Professionnel de sant&eacute; peut d&eacute;cider
        de revoir &agrave; la baisse ou de ne pas faire payer la
        T&eacute;l&eacute;consultation.
      </span>
    </p>
    <p class="c112">
      <span class="c1"
        >L&rsquo;Utilisateur pourra ensuite recevoir dans son espace </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1"
        >la note d&rsquo;honoraire correspondante, et le cas
        &eacute;ch&eacute;ant, le compte rendu de la
        t&eacute;l&eacute;consultation et/ou une prescription, en se rendant
        dans la Rubrique &laquo; mes rendez-vous &raquo; et en
        s&eacute;lectionnant la T&eacute;l&eacute;consultation
        r&eacute;alis&eacute;e afin de t&eacute;l&eacute;charger lesdits
        Documents. L&rsquo;&eacute;tablissement d&rsquo;une prescription est
        &agrave; l&rsquo;appr&eacute;ciation du Professionnel de Sant&eacute;.
        La transmission par l&#39;Utilisateur de ces Documents contenant des
        Donn&eacute;es personnelles &agrave; caract&egrave;re personnel de
        Sant&eacute; &agrave; tout tiers se fait sous sa seule
        responsabilit&eacute;.
      </span>
    </p>
    <p class="c4">
      <span class="c0 c3">dok.ma </span
      ><span class="c1"
        >d&eacute;cline toute responsabilit&eacute; en cas de litige, quelle
        qu&rsquo;en soit la raison, entre l&rsquo;Utilisateur et un
        Professionnel de sant&eacute;, non directement imputable &agrave; </span
      ><span class="c0 c3">dok.ma</span><span class="c1">. </span>
    </p>
    <p class="c44">
      <span class="c1"
        >Les incivilit&eacute;s, verbales ou &eacute;crites, &agrave;
        l&rsquo;encontre des Professionnels de Sant&eacute;
        t&eacute;l&eacute;consultants peuvent faire l&rsquo;objet de poursuites
        et de la suppression du compte </span
      ><span class="c0 c3">dok.ma </span
      ><span class="c1">de l&rsquo;Utilisateur. </span>
    </p>
    <p class="c123">
      <span class="c0 c3">dok.ma </span
      ><span class="c1"
        >ne peut s&rsquo;assurer de la conformit&eacute; de son produit et son
        utilisation au-del&agrave; de la juridiction marocaine.</span
      >
    </p>
    <div>
      <p class="c49 c132"><span class="c59 c3"></span></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c1 {
  color: #425e6f;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Tahoma";
  font-style: normal;
}
.c77 {
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-size: 9.5pt;
  font-family: "Tahoma";
}
.c21 {
  margin-left: -7.1pt;
  padding-top: 13.5pt;
  padding-bottom: 0pt;
  line-height: 1.2065484523773193;
  text-align: justify;
  margin-right: -0.8pt;
  height: 11pt;
}
.c85 {
  margin-left: -7.1pt;
  padding-top: 8.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c123 {
  margin-left: -7.1pt;
  padding-top: 9pt;
  padding-bottom: 0pt;
  line-height: 1.194025993347168;
  text-align: justify;
  margin-right: -0.8pt;
}
.c81 {
  margin-left: -7.1pt;
  padding-top: 9.1pt;
  padding-bottom: 0pt;
  line-height: 1.206559419631958;
  text-align: justify;
  margin-right: -0.8pt;
}
.c30 {
  margin-left: -7.1pt;
  padding-top: 1.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c84 {
  margin-left: -7.1pt;
  padding-top: 10.4pt;
  padding-bottom: 0pt;
  line-height: 1.0099337100982666;
  text-align: justify;
  margin-right: -0.8pt;
}
.c26 {
  margin-left: -7.1pt;
  padding-top: 9pt;
  padding-bottom: 0pt;
  line-height: 1.2065484523773193;
  text-align: justify;
  margin-right: -0.8pt;
}
.c46 {
  margin-left: -7.1pt;
  padding-top: 8.9pt;
  padding-bottom: 0pt;
  line-height: 1.206556797027588;
  text-align: justify;
  margin-right: -0.8pt;
}
.c55 {
  margin-left: -7.1pt;
  padding-top: 1.9pt;
  padding-bottom: 0pt;
  line-height: 1.17562997341156;
  text-align: justify;
  margin-right: -0.8pt;
}
.c78 {
  margin-left: -7.1pt;
  padding-top: 2.2pt;
  padding-bottom: 0pt;
  line-height: 1.1966348886489868;
  text-align: justify;
  margin-right: -0.8pt;
}
.c127 {
  margin-left: -7.1pt;
  padding-top: 9.1pt;
  padding-bottom: 0pt;
  line-height: 1.2065128087997437;
  text-align: justify;
  margin-right: -0.8pt;
}
.c31 {
  margin-left: -7.1pt;
  padding-top: 13.6pt;
  padding-bottom: 0pt;
  line-height: 1.2065502405166626;
  text-align: justify;
  margin-right: -0.8pt;
}
.c143 {
  margin-left: -7.1pt;
  padding-top: 1.6pt;
  padding-bottom: 0pt;
  line-height: 1.1908648014068604;
  text-align: justify;
  margin-right: -0.8pt;
}
.c93 {
  margin-left: -7.1pt;
  padding-top: 14.1pt;
  padding-bottom: 0pt;
  line-height: 1.2065484523773193;
  text-align: justify;
  margin-right: -0.8pt;
}
.c139 {
  margin-left: -7.1pt;
  padding-top: 15.7pt;
  padding-bottom: 0pt;
  line-height: 1.8763751983642578;
  text-align: justify;
  margin-right: -0.8pt;
}
.c96 {
  margin-left: -7.1pt;
  padding-top: 9.2pt;
  padding-bottom: 0pt;
  line-height: 1.1939903497695923;
  text-align: justify;
  margin-right: -0.8pt;
}
.c122 {
  margin-left: -7.1pt;
  padding-top: 0.7pt;
  padding-bottom: 0pt;
  line-height: 1.19398832321167;
  text-align: justify;
  margin-right: -0.8pt;
}
.c48 {
  margin-left: -7.1pt;
  padding-top: 1.6pt;
  padding-bottom: 0pt;
  line-height: 1.190929651260376;
  text-align: justify;
  margin-right: -0.8pt;
}
.c61 {
  margin-left: -7.1pt;
  padding-top: 2.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c41 {
  margin-left: -7.1pt;
  padding-top: 8.3pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c34 {
  margin-left: -7.1pt;
  padding-top: 8.7pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c33 {
  margin-left: -7.1pt;
  padding-top: 8.9pt;
  padding-bottom: 0pt;
  line-height: 1.2065118551254272;
  text-align: justify;
  margin-right: -0.8pt;
}
.c79 {
  margin-left: -7.1pt;
  padding-top: 9pt;
  padding-bottom: 0pt;
  line-height: 1.1940209865570068;
  text-align: justify;
  margin-right: -0.8pt;
}
.c43 {
  margin-left: -7.1pt;
  padding-top: 0.7pt;
  padding-bottom: 0pt;
  line-height: 1.196541428565979;
  text-align: justify;
  margin-right: -0.8pt;
}
.c47 {
  margin-left: -7.1pt;
  padding-top: 8.1pt;
  padding-bottom: 0pt;
  line-height: 1.013945460319519;
  text-align: justify;
  margin-right: -0.8pt;
}
.c114 {
  margin-left: -7.1pt;
  padding-top: 1.4pt;
  padding-bottom: 0pt;
  line-height: 1.1969232559204102;
  text-align: justify;
  margin-right: -0.8pt;
}
.c27 {
  margin-left: -7.1pt;
  padding-top: 9.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c98 {
  margin-left: -7.1pt;
  padding-top: 9pt;
  padding-bottom: 0pt;
  line-height: 1.194042444229126;
  text-align: justify;
  margin-right: -0.8pt;
}
.c134 {
  margin-left: -7.1pt;
  padding-top: 7.6pt;
  padding-bottom: 0pt;
  line-height: 1.1143815517425537;
  text-align: justify;
  margin-right: -0.8pt;
}
.c25 {
  margin-left: -7.1pt;
  padding-top: 9.6pt;
  padding-bottom: 0pt;
  line-height: 1.2065637111663818;
  text-align: justify;
  margin-right: -0.8pt;
}
.c18 {
  margin-left: -7.1pt;
  padding-top: 0.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c17 {
  margin-left: -7.1pt;
  padding-top: 8.8pt;
  padding-bottom: 0pt;
  line-height: 1.206510305404663;
  text-align: justify;
  margin-right: -0.8pt;
}
.c63 {
  margin-left: -7.1pt;
  padding-top: 8.4pt;
  padding-bottom: 0pt;
  line-height: 1.2064861059188843;
  text-align: justify;
  margin-right: -0.8pt;
}
.c104 {
  margin-left: -7.1pt;
  padding-top: 0.7pt;
  padding-bottom: 0pt;
  line-height: 1.1966253519058228;
  text-align: justify;
  margin-right: -0.8pt;
}
.c130 {
  margin-left: -7.1pt;
  padding-top: 8.5pt;
  padding-bottom: 0pt;
  line-height: 1.2065472602844238;
  text-align: justify;
  margin-right: -0.8pt;
}
.c73 {
  margin-left: -7.1pt;
  padding-top: 0.7pt;
  padding-bottom: 0pt;
  line-height: 1.1966099739074707;
  text-align: justify;
  margin-right: -0.8pt;
}
.c32 {
  margin-left: -7.1pt;
  padding-top: 9pt;
  padding-bottom: 0pt;
  line-height: 1.2065459489822388;
  text-align: justify;
  margin-right: -0.8pt;
}
.c29 {
  margin-left: -7.1pt;
  padding-top: 8.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c144 {
  margin-left: -7.1pt;
  padding-top: 18.5pt;
  padding-bottom: 0pt;
  line-height: 1.1993237733840942;
  text-align: justify;
  margin-right: -0.8pt;
}
.c52 {
  margin-left: -7.1pt;
  padding-top: 8.6pt;
  padding-bottom: 0pt;
  line-height: 1.193827748298645;
  text-align: justify;
  margin-right: -0.8pt;
}
.c91 {
  margin-left: -7.1pt;
  padding-top: 4.8pt;
  padding-bottom: 0pt;
  line-height: 1.1939395666122437;
  text-align: justify;
  margin-right: -0.8pt;
}
.c145 {
  margin-left: -7.1pt;
  padding-top: 8.8pt;
  padding-bottom: 0pt;
  line-height: 1.2066144943237305;
  text-align: justify;
  margin-right: -0.8pt;
}
.c67 {
  margin-left: -7.1pt;
  padding-top: 1pt;
  padding-bottom: 0pt;
  line-height: 1.1957342624664307;
  text-align: justify;
  margin-right: -0.8pt;
}
.c142 {
  margin-left: -7.1pt;
  padding-top: 8.8pt;
  padding-bottom: 0pt;
  line-height: 1.206613302230835;
  text-align: justify;
  margin-right: -0.8pt;
}
.c64 {
  margin-left: -7.1pt;
  padding-top: 14.1pt;
  padding-bottom: 0pt;
  line-height: 1.2064992189407349;
  text-align: justify;
  margin-right: -0.8pt;
}
.c132 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: right;
}
.c40 {
  margin-left: -7.1pt;
  padding-top: 2.2pt;
  padding-bottom: 0pt;
  line-height: 1.1719324588775635;
  text-align: justify;
  margin-right: -0.8pt;
}
.c112 {
  margin-left: -7.1pt;
  padding-top: 8.9pt;
  padding-bottom: 0pt;
  line-height: 1.2065876722335815;
  text-align: justify;
  margin-right: -0.8pt;
}
.c92 {
  margin-left: -7.1pt;
  padding-top: 10.2pt;
  padding-bottom: 0pt;
  line-height: 1.199285626411438;
  text-align: justify;
  margin-right: -0.8pt;
}
.c2 {
  margin-left: -7.1pt;
  padding-top: 8.5pt;
  padding-bottom: 0pt;
  line-height: 1.2065496444702148;
  text-align: justify;
  margin-right: -0.8pt;
}
.c62 {
  margin-left: -7.1pt;
  padding-top: 16.3pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c126 {
  margin-left: -7.1pt;
  padding-top: 13.5pt;
  padding-bottom: 0pt;
  line-height: 1.1939243078231812;
  text-align: justify;
  margin-right: -0.8pt;
}
.c116 {
  margin-left: -7.1pt;
  padding-top: 9.1pt;
  padding-bottom: 0pt;
  line-height: 1.1940044164657593;
  text-align: justify;
  margin-right: -0.8pt;
}
.c11 {
  margin-left: -7.1pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1437327861785889;
  text-align: justify;
  margin-right: -0.8pt;
}
.c53 {
  margin-left: -7.1pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1516584157943726;
  text-align: justify;
  margin-right: -0.8pt;
}
.c42 {
  margin-left: -7.1pt;
  padding-top: 8.8pt;
  padding-bottom: 0pt;
  line-height: 1.206544041633606;
  text-align: justify;
  margin-right: -0.8pt;
}
.c57 {
  margin-left: -7.1pt;
  padding-top: 15.1pt;
  padding-bottom: 0pt;
  line-height: 1.2065484523773193;
  text-align: justify;
  margin-right: -0.8pt;
}
.c90 {
  margin-left: -7.1pt;
  padding-top: 10.4pt;
  padding-bottom: 0pt;
  line-height: 1.2033315896987915;
  text-align: justify;
  margin-right: -0.8pt;
}
.c136 {
  margin-left: -7.1pt;
  padding-top: 13.4pt;
  padding-bottom: 0pt;
  line-height: 1.2065484523773193;
  text-align: justify;
  margin-right: -0.8pt;
}
.c69 {
  margin-left: -7.1pt;
  padding-top: 13.2pt;
  padding-bottom: 0pt;
  line-height: 1.1730090379714966;
  text-align: justify;
  margin-right: -0.8pt;
}
.c75 {
  margin-left: -7.1pt;
  padding-top: 2pt;
  padding-bottom: 0pt;
  line-height: 1.1987957954406738;
  text-align: justify;
  margin-right: -0.8pt;
}
.c56 {
  margin-left: -7.1pt;
  padding-top: 11.7pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c10 {
  margin-left: -7.1pt;
  padding-top: 8.4pt;
  padding-bottom: 0pt;
  line-height: 1.2064670324325562;
  text-align: justify;
  margin-right: -0.8pt;
}
.c135 {
  margin-left: -7.1pt;
  padding-top: 9.6pt;
  padding-bottom: 0pt;
  line-height: 1.2065486907958984;
  text-align: justify;
  margin-right: -0.8pt;
}
.c23 {
  margin-left: -7.1pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.2066450119018555;
  text-align: justify;
  margin-right: -0.8pt;
}
.c133 {
  margin-left: -7.1pt;
  padding-top: 8.6pt;
  padding-bottom: 0pt;
  line-height: 1.2065554857254028;
  text-align: justify;
  margin-right: -0.8pt;
}
.c95 {
  margin-left: -7.1pt;
  padding-top: 9.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c119 {
  margin-left: -7.1pt;
  padding-top: 8.9pt;
  padding-bottom: 0pt;
  line-height: 1.1940513849258423;
  text-align: justify;
  margin-right: -0.8pt;
}
.c138 {
  margin-left: -7.1pt;
  padding-top: 13.7pt;
  padding-bottom: 0pt;
  line-height: 1.2065484523773193;
  text-align: justify;
  margin-right: -0.8pt;
}
.c16 {
  margin-left: -7.1pt;
  padding-top: 13.4pt;
  padding-bottom: 0pt;
  line-height: 1.1940056085586548;
  text-align: justify;
  margin-right: -0.8pt;
}
.c109 {
  margin-left: -7.1pt;
  padding-top: 9.8pt;
  padding-bottom: 0pt;
  line-height: 1.1939243078231812;
  text-align: justify;
  margin-right: -0.8pt;
}
.c45 {
  margin-left: -7.1pt;
  padding-top: 8.9pt;
  padding-bottom: 0pt;
  line-height: 1.2064162492752075;
  text-align: justify;
  margin-right: -0.8pt;
}
.c24 {
  margin-left: -7.1pt;
  padding-top: 13.5pt;
  padding-bottom: 0pt;
  line-height: 1.1940209865570068;
  text-align: justify;
  margin-right: -0.8pt;
}
.c100 {
  margin-left: -7.1pt;
  padding-top: 3pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c74 {
  margin-left: -7.1pt;
  padding-top: 9.3pt;
  padding-bottom: 0pt;
  line-height: 1.206566333770752;
  text-align: justify;
  margin-right: -0.8pt;
}
.c140 {
  margin-left: -7.1pt;
  padding-top: 11.1pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c82 {
  margin-left: -7.1pt;
  padding-top: 10.2pt;
  padding-bottom: 0pt;
  line-height: 1.1939561367034912;
  text-align: justify;
  margin-right: -0.8pt;
}
.c54 {
  margin-left: -7.1pt;
  padding-top: 18.6pt;
  padding-bottom: 0pt;
  line-height: 1.2065484523773193;
  text-align: justify;
  margin-right: -0.8pt;
}
.c66 {
  margin-left: -7.1pt;
  padding-top: 12.9pt;
  padding-bottom: 0pt;
  line-height: 1.1939395666122437;
  text-align: justify;
  margin-right: -0.8pt;
}
.c14 {
  margin-left: -7.1pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c22 {
  margin-left: -7.1pt;
  padding-top: 13.7pt;
  padding-bottom: 0pt;
  line-height: 1.2065489292144775;
  text-align: justify;
  margin-right: -0.8pt;
}
.c101 {
  margin-left: -7.1pt;
  padding-top: 0.7pt;
  padding-bottom: 0pt;
  line-height: 1.1966240406036377;
  text-align: justify;
  margin-right: -0.8pt;
}
.c113 {
  margin-left: -7.1pt;
  padding-top: 8.7pt;
  padding-bottom: 0pt;
  line-height: 1.2065719366073608;
  text-align: justify;
  margin-right: -0.8pt;
}
.c108 {
  margin-left: -7.1pt;
  padding-top: 8.9pt;
  padding-bottom: 0pt;
  line-height: 1.1939700841903687;
  text-align: justify;
  margin-right: -0.8pt;
}
.c7 {
  margin-left: -7.1pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1939878463745117;
  text-align: justify;
  margin-right: -0.8pt;
}
.c71 {
  margin-left: -7.1pt;
  padding-top: 14pt;
  padding-bottom: 0pt;
  line-height: 1.2065484523773193;
  text-align: justify;
  margin-right: -0.8pt;
}
.c70 {
  margin-left: -7.1pt;
  padding-top: 23.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c9 {
  margin-left: -7.1pt;
  padding-top: 10.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c89 {
  margin-left: -7.1pt;
  padding-top: 8.6pt;
  padding-bottom: 0pt;
  line-height: 1.2065154314041138;
  text-align: justify;
  margin-right: -0.8pt;
}
.c19 {
  margin-left: -7.1pt;
  padding-top: 15.3pt;
  padding-bottom: 0pt;
  line-height: 0.9578312039375305;
  text-align: center;
  margin-right: -0.8pt;
}
.c80 {
  margin-left: -7.1pt;
  padding-top: 9.8pt;
  padding-bottom: 0pt;
  line-height: 1.1889621019363403;
  text-align: justify;
  margin-right: -0.8pt;
}
.c68 {
  margin-left: -7.1pt;
  padding-top: 9pt;
  padding-bottom: 0pt;
  line-height: 1.062708854675293;
  text-align: justify;
  margin-right: -0.8pt;
}
.c115 {
  margin-left: -7.1pt;
  padding-top: 13.4pt;
  padding-bottom: 0pt;
  line-height: 1.206549048423767;
  text-align: justify;
  margin-right: -0.8pt;
}
.c128 {
  margin-left: -7.1pt;
  padding-top: 13.7pt;
  padding-bottom: 0pt;
  line-height: 1.1939667463302612;
  text-align: justify;
  margin-right: -0.8pt;
}
.c44 {
  margin-left: -7.1pt;
  padding-top: 9pt;
  padding-bottom: 0pt;
  line-height: 1.1939548254013062;
  text-align: justify;
  margin-right: -0.8pt;
}
.c83 {
  margin-left: -7.1pt;
  padding-top: 8.8pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c87 {
  margin-left: -7.1pt;
  padding-top: 13.6pt;
  padding-bottom: 0pt;
  line-height: 1.2065484523773193;
  text-align: justify;
  margin-right: -0.8pt;
}
.c51 {
  margin-left: -7.1pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0034831762313843;
  text-align: justify;
  margin-right: -0.8pt;
}
.c103 {
  margin-left: -7.1pt;
  padding-top: 19.1pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c28 {
  margin-left: -7.1pt;
  padding-top: 13.5pt;
  padding-bottom: 0pt;
  line-height: 1.2064518928527832;
  text-align: justify;
  margin-right: -0.8pt;
}
.c72 {
  margin-left: -7.1pt;
  padding-top: 14.3pt;
  padding-bottom: 0pt;
  line-height: 1.1646815538406372;
  text-align: justify;
  margin-right: -0.8pt;
}
.c120 {
  margin-left: -7.1pt;
  padding-top: 8.5pt;
  padding-bottom: 0pt;
  line-height: 1.2065891027450562;
  text-align: justify;
  margin-right: -0.8pt;
}
.c121 {
  margin-left: -7.1pt;
  padding-top: 26.9pt;
  padding-bottom: 0pt;
  line-height: 1.012233018875122;
  text-align: justify;
  margin-right: -0.8pt;
}
.c50 {
  margin-left: -7.1pt;
  padding-top: 7.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c117 {
  margin-left: -7.1pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.214911937713623;
  text-align: justify;
  margin-right: -0.8pt;
}
.c105 {
  margin-left: -7.1pt;
  padding-top: 8.8pt;
  padding-bottom: 0pt;
  line-height: 1.2065683603286743;
  text-align: justify;
  margin-right: -0.8pt;
}
.c111 {
  margin-left: -7.1pt;
  padding-top: 8.3pt;
  padding-bottom: 0pt;
  line-height: 1.2065140008926392;
  text-align: justify;
  margin-right: -0.8pt;
}
.c99 {
  margin-left: -7.1pt;
  padding-top: 10.2pt;
  padding-bottom: 0pt;
  line-height: 1.2150086164474487;
  text-align: justify;
  margin-right: -0.8pt;
}
.c39 {
  margin-left: -7.1pt;
  padding-top: 8.4pt;
  padding-bottom: 0pt;
  line-height: 1.206552267074585;
  text-align: justify;
  margin-right: -0.8pt;
}
.c65 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c35 {
  margin-left: -7.1pt;
  padding-top: 8.5pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c4 {
  margin-left: -7.1pt;
  padding-top: 8.4pt;
  padding-bottom: 0pt;
  line-height: 1.2147823572158813;
  text-align: justify;
  margin-right: -0.8pt;
}
.c110 {
  margin-left: -7.1pt;
  padding-top: 9.2pt;
  padding-bottom: 0pt;
  line-height: 1.9837642908096313;
  text-align: justify;
  margin-right: -0.8pt;
}
.c97 {
  margin-left: -7.1pt;
  padding-top: 8.9pt;
  padding-bottom: 0pt;
  line-height: 1.206580638885498;
  text-align: justify;
  margin-right: -0.8pt;
}
.c88 {
  margin-left: -7.1pt;
  padding-top: 9.4pt;
  padding-bottom: 0pt;
  line-height: 1.206551432609558;
  text-align: justify;
  margin-right: -0.8pt;
}
.c107 {
  margin-left: -7.1pt;
  padding-top: 8.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -0.8pt;
}
.c131 {
  margin-left: -7.1pt;
  padding-top: 13.3pt;
  padding-bottom: 0pt;
  line-height: 1.206504464149475;
  text-align: justify;
  margin-right: -0.8pt;
}
.c141 {
  margin-left: -7.1pt;
  padding-top: 9.2pt;
  padding-bottom: 0pt;
  line-height: 1.1939407587051392;
  text-align: justify;
  margin-right: -0.8pt;
}
.c86 {
  margin-left: -7.1pt;
  padding-top: 13.5pt;
  padding-bottom: 0pt;
  line-height: 1.2065484523773193;
  text-align: justify;
  margin-right: -0.8pt;
}
.c36 {
  margin-left: -7.1pt;
  padding-top: 8.9pt;
  padding-bottom: 0pt;
  line-height: 1.2065556049346924;
  text-align: justify;
  margin-right: -0.8pt;
}
.c37 {
  margin-left: -7.1pt;
  padding-top: 8.6pt;
  padding-bottom: 0pt;
  line-height: 1.21489417552948;
  text-align: justify;
  margin-right: -0.8pt;
}
.c13 {
  margin-left: -7.1pt;
  padding-top: 0.9pt;
  padding-bottom: 0pt;
  line-height: 1.1919398307800293;
  text-align: justify;
  margin-right: -0.8pt;
}
.c76 {
  margin-left: -7.1pt;
  padding-top: 8.7pt;
  padding-bottom: 0pt;
  line-height: 1.194025993347168;
  text-align: justify;
  margin-right: -0.8pt;
}
.c137 {
  margin-left: -7.1pt;
  padding-top: 9.5pt;
  padding-bottom: 0pt;
  line-height: 1.1939914226531982;
  text-align: justify;
  margin-right: -0.8pt;
}
.c106 {
  margin-left: -7.1pt;
  padding-top: 13.9pt;
  padding-bottom: 0pt;
  line-height: 1.1809239387512207;
  text-align: justify;
  margin-right: -0.8pt;
}
.c58 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-style: normal;
}
.c12 {
  color: #425e6f;
  font-weight: 400;
  font-size: 10pt;
  font-family: "Arial";
}
.c59 {
  color: #000000;
  font-weight: 400;
  font-size: 11pt;
  font-family: "Arial";
}
.c38 {
  color: #0494de;
  font-weight: 400;
  font-size: 10pt;
  font-family: "Tahoma";
}
.c94 {
  color: #204257;
  font-weight: 400;
  font-size: 14.5pt;
  font-family: "Verdana";
}
.c6 {
  color: #425e6f;
  font-weight: 700;
  font-size: 10pt;
  font-family: "Tahoma";
}
.c15 {
  color: #204257;
  font-weight: 700;
  font-size: 11pt;
  font-family: "Verdana";
}
.c5 {
  font-size: 10pt;
  font-family: "Tahoma";
  color: #425e6f;
  font-weight: 400;
}
.c118 {
  color: #204257;
  font-weight: 700;
  font-size: 11pt;
  font-family: "Arial";
}
.c0 {
  font-size: 10pt;
  font-family: "Tahoma";
  color: #0000ff;
  font-weight: 400;
}
.c8 {
  font-size: 10pt;
  font-family: "Tahoma";
  color: #204257;
  font-weight: 400;
}
.c125 {
  color: #204257;
  font-weight: 400;
  font-size: 21pt;
  font-family: "Arial";
}
.c102 {
  background-color: #ffffff;
  /* padding: 35pt 62.4pt 100.9pt 65.6pt; */
}
.c3 {
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c129 {
  font-size: 13pt;
  color: #204257;
  font-weight: 700;
}
.c124 {
  font-weight: 400;
  font-family: "Verdana";
}
.c20 {
  color: #425e6f;
  font-size: 10pt;
}
.c60 {
  color: inherit;
  text-decoration: inherit;
}
.c49 {
  height: 11pt;
}
.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
